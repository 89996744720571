import React, { useState } from 'react'

const RESUME_S3_URL = "https://tucker-klutey-resume.s3.amazonaws.com/Tucker-Klutey-resume.pdf";
const FORMSPREE_API_URL = "https://formspree.io/f/xgepvbbk";

export default function ResumeAuth(props) {

  const [canViewResume, setCanViewResume] = useState(false);
  return (
    <article
      id="resume"
      className={`${props.article === 'resume' ? 'active' : ''} ${
        props.articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <strong>Looks like you want to get to know me better.</strong>
      <p>Let me learn a little about you first.</p>
      <form
        onSubmit={submitForm}
        action={FORMSPREE_API_URL}
        method="POST"
      >
        <div className="field">
          <label htmlFor="name">Full Name</label>
          <input type="text" name="name" id="name" required={!canViewResume} />
        </div>
        <div className="field">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" required={!canViewResume} />
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Submit" className="special" disabled={canViewResume} />
          </li>
          <li>
            <button className="special" onClick={navigateToResume} disabled={!canViewResume}>View Resume</button>
          </li>
        </ul>
      </form>
      {props.close}
    </article>
  )

  function navigateToResume() {
    window.location.href = RESUME_S3_URL;
  }
  function submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setCanViewResume(true);
      }
    };
    xhr.send(data);
  }
}
