import PropTypes from 'prop-types'
import React from 'react'
import Contact from 'src/components/subpages/Contact'
import ResumeAuth from './subpages/ResumeAuth'

function Main(props) {

  let close = (
    <div
      className="close"
      role="button"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <ResumeAuth
        article={props.article}
        articleTimeout={props.articleTimeout}
        close={close}
      />
      <Contact
        article={props.article}
        articleTimeout={props.articleTimeout}
        close={close}
      />
    </div>
  )
}

Main.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
