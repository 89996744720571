import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Tucker from '../images/Tucker-pro_pic.jpg';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center
`

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div>
      <img className="logo" src={Tucker} />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Tucker Klutey</h1>
        <div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={10}
            totalSlides={3}
            step={1}
            interval={2000}
            orientation={'vertical'}
            isPlaying
          >
            <Slider>
              <Slide index={0}>
                <p>
                  TEACHER
                </p>
              </Slide>
              <Slide index={1}>
                <p>
                  ENGINEER
                </p>
              </Slide>
              <Slide index={2}>
                <p>
                  TECHNOLOGIST
                </p>
              </Slide>
            </Slider>
          </CarouselProvider>
        </div>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <CenteredContainer>
            <button
              onClick={() => {
                props.onOpenArticle('resume')
              }}
            >
              Resume
            </button>
          </CenteredContainer>
        </li>
        <li>
          <CenteredContainer>
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              }}
            >
              Contact
            </button>
          </CenteredContainer>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
