import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'


const icons = [
  {
    key: 'github',
    name: 'GitHub',
    icon: faGithub
  },
  {
    key: 'linkedin',
    name: 'LinkedIn',
    icon: faLinkedin
  },
]

export default function IconLink(props) {
  const [ isHovered, setIsHovered ] = useState(false);
  const icon = icons.filter(i => i.key === props.keyName)[0]
  const content = isHovered ? <h3>{icon.name}</h3> : <FontAwesomeIcon icon={icon.icon} size="6x" />;

  return (
    <a onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} href={props.link}>
      {content}
    </a>


)
}
