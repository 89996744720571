import React from 'react'
import IconLink from 'src/components/IconLink'
import styled from 'styled-components'


const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: auto;
  align-items: center; 
`

export default function Contact(props) {
  return (
    <article
      id="contact"
      className={`${props.article === 'contact' ? 'active' : ''} ${
        props.articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h2 className="major">Contact</h2>
      <CenteredContainer>
        <ul className="icons">
          <li>
            <IconLink keyName="github" link="https://github.com/tklutey" />
          </li>
          <li>
            <IconLink
              keyName="linkedin"
              link="https://www.linkedin.com/in/tucker-klutey-036b82b3"
            />
          </li>
        </ul>
      </CenteredContainer>
      {props.close}
    </article>
  )
}
